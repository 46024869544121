import React from 'react'
import Type, {TypeQueryData} from '../../templates/type'
import {graphql} from "gatsby";

interface Props {
    data: TypeQueryData
}
export default ({data}: Props) => {
    return (
        <Type data={data}>
        </Type>
    );
}

export const pageQuery = graphql`
    query {
        file(name: {eq: "type-b"}) {
            childMarkdownRemark {
                ...TypePage
            }
        }
        bouwtype(diversen: {objectcode: {eq: "O100088"}}) {
            ...Bouwtype
        }
        other: allBouwtype(filter: {diversen: {objectcode: {ne: "O100088"}}}) {
            nodes {
                algemeen {
                    omschrijving
                }
                fields {
                    slug
                }
            }
        }
    }
`
